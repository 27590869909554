import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/arrow.scss";
import { directionArrow } from "../widgets/SVG";
const cl = _classes(styles);

Arrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "down", "up"]),
  color: PropTypes.oneOf(["white", "yellow"]),
  arrowClassName:PropTypes.string
};

export default function Arrow({
  className,
  direction,
  onClick,
  color,
  arrowClassName=""
}) {
  return (
    <button
      onClick={onClick}
      className={`${className} ${color} ${cl([
        "_",
        color,
        ...arrowClassName.split(" "),
        direction === "left" ? "left" : "right"])}`}
    >
      {directionArrow}
    </button>
  );
}
