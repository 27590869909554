import { _classes } from "@scripts/utilities/helpers";
import styles from "./PressCarousel.scss";
import { Carousel } from "../../../widgets";

const cl = _classes(styles);

PressCarousel.propTypes = {
  slides: PropTypes.array,
};

export default function PressCarousel({ slides }) {
  console.log(slides);
  return (
    <section className={cl("root")}>
      <Carousel slides={slides} isPress />
    </section>
  );
}
