import { _classes } from "@scripts/utilities/helpers";
import styles from "./TeamInfo.scss";
import { Container, Text } from "@scripts/widgets";

const cl = _classes(styles);

TeamInfo.propTypes = {
  title: PropTypes.string,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  leftTeam: PropTypes.string,
  rightTeam: PropTypes.string,
};

export default function TeamInfo({
  title,
  leftTitle,
  rightTitle,
  leftTeam,
  rightTeam,
}) {
  return (
    <section className={cl("root")}>
      <Container className={cl("container")} extraPadding>
        <Text
          text={title}
          variant="h2"
          component="h2"
          className={cl(["text", "title"])}
        />

        <div className={cl("columns")}>
          <div className={cl("left")}>
            <Text
              text={leftTitle}
              variant="h2"
              component="h3"
              className={cl("subtitle")}
            />
            <ul className={cl("list")}>
              {leftTeam.map((person) => (
                <li key={person.id} className={cl("item")}>
                  <Text
                    text={person.h1}
                    variant="body1"
                    component="h3"
                    className={cl("item_title")}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: person.blurb1 }}
                    className={cl("item_description")}
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className={cl("right")}>
            <Text
              text={rightTitle}
              variant="h2"
              component="h3"
              className={cl("subtitle")}
            />
            <ul className={cl("list")}>
              {rightTeam.map((person) => (
                <li key={person.id} className={cl("item")}>
                  <Text
                    text={person.h1}
                    variant="body1"
                    component="h3"
                    className={cl("item_title")}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: person.blurb1 }}
                    className={cl("item_description")}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
}
