import { Text, Link } from "@scripts/widgets";
import { _classes } from "@scripts/utilities/helpers";
import { motion, AnimatePresence } from "@scripts/utilities/FramerMotion";
import styles from "./MobileNav.scss";
import { useEffect, useState } from "react";
import Hamburger from "../../Hamburger";
import { useAppState } from "@scripts/providers";

const cl = _classes(styles);

MobileNav.propTypes = {
  children: PropTypes.children,
  scrolled: PropTypes.bool,
  items: PropTypes.array,
  toggleContactModal: PropTypes.func,
};

export default function MobileNav({
  children,
  scrolled,
  items,
  toggleContactModal,
}) {
  const [visible, toggle] = useState(false);
  const { childrenByPath, byTemplate } = useAppState();

  const officesPath = byTemplate("offices")[0]?.path;
  const prebuiltOfficesPath = byTemplate("prebuilt_offices")[0]?.path;

  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  //This isn't coded right. If the client adds more childpages, this will break. No time currently in retainer
  const renderChildren = (childItems) => {
    return childItems.map((item) => {
      return (
        <span>
          <li
            key={item.id}
            className={cl("child_link")}
            onClick={() => toggle(false)}
          >
            <Link
              className={cl("mobile_sublink")}
              path={officesPath}
              onClick={() => toggle(false)}
            >
              <Text
                text={"Full Floor Offices"}
                font="secondary"
                variant="mobileNavChild"
                className={cl("text")}
              />
            </Link>
          </li>
          <li key={item.id} className={cl("child_link")}>
            <Link
              className={cl("mobile_sublink")}
              path={prebuiltOfficesPath}
              onClick={() => toggle(false)}
            >
              <Text
                text={"Pre-Built Offices"}
                font="secondary"
                variant="mobileNavChild"
                className={cl("text")}
              />
            </Link>
          </li>
        </span>
      );
    });
  };

  const renderNavLinks = () => (
    <motion.ul variants={variantsul} className={cl(["list"])}>
      {items?.map((item) => {
        const itemWithChildrenForNav = childrenByPath(item.path)?.filter(
          (item) => !item.template.match(/map|incentives/)
        );
        console.log("console.log", itemWithChildrenForNav);
        const activePath = window.location.pathname.match(item.displayPath);

        return (
          <motion.li
            variants={variants_li}
            key={item.id}
            className={cl(["item"])}
          >
            {itemWithChildrenForNav && itemWithChildrenForNav.length ? (
              <>
                <Text
                  text={item.linktitle}
                  className={cl(["link", "link_text", "no_link"])}
                  font="bold"
                  component="p"
                />
                <ul className={cl(["child_menu", activePath && "active"])}>
                  {renderChildren(itemWithChildrenForNav)}
                </ul>
              </>
            ) : (
              <Link
                path={item.path}
                className={cl(["link", activePath && "active"])}
                onClick={() => toggle(false)}
              >
                <Text
                  text={item.linktitle}
                  font="bold"
                  className={cl("link_text")}
                  component="p"
                />
                {itemWithChildrenForNav.length ? (
                  <ul className={cl(["child_menu", activePath && "active"])}>
                    {renderChildren(itemWithChildrenForNav)}
                  </ul>
                ) : (
                  ""
                )}
              </Link>
            )}
          </motion.li>
        );
      })}
      <motion.li className={cl(["item"])} variants={variants_li}>
        <Link
          onClick={toggleContactModal}
          className={cl(["link", "contact_button_item"])}
        >
          <Text
            text={"Learn More"}
            font="bold"
            className={cl(["link_text"])}
            component="p"
          />
        </Link>
      </motion.li>
    </motion.ul>
  );

  return (
    <nav className={cl(["root", visible && "open", scrolled && "scrolled"])}>
      <div className={cl("left")}>
        <Hamburger toggle={() => toggle(!visible)} open={visible} />
      </div>
      <div className={cl("center")}>{children}</div>
      <div className={cl("right")}>
        <div className={cl(["item", "contact", !visible && "active"])}>
          <Link
            onClick={toggleContactModal}
            className={cl(["link", "contact_button"])}
          >
            <Text
              text={"Learn More"}
              font="bold"
              className={cl("link_text", "contact_text")}
              component="p"
            />
          </Link>
        </div>
      </div>

      {ReactDOM.createPortal(
        <AnimatePresence exitBeforeEnter>
          {visible && (
            <motion.div
              animate={visible ? "open" : "closed"}
              variants={variantsnav}
              initial="closed"
              exit="closed"
              className={cl(["panel", scrolled && "scrolled"])}
            >
              {renderNavLinks()}
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </nav>
  );
}

const variantsnav = {
  open: {
    opacity: 1,
    pointerEvent: "all",
    x: 0,
    transition: { duration: 0.5 },
  },
  closed: {
    opacity: 1,
    transition: { delay: 0.4, x: { duration: 0.4, ease: "easeInOut" } },
    pointerEvent: "none",
    x: "-100%",
  },
};
const variantsul = {
  open: {
    transition: { staggerChildren: 0.05, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.03, staggerDirection: -1 },
  },
};

const variants_li = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 500, velocity: -100 },
    },
  },
  closed: {
    x: -30,
    opacity: 0,
    transition: {
      x: { stiffness: 500 },
    },
  },
};
