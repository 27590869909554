import React, { useEffect, useState } from "react";
import styles from "./CookiePopup.scss";
import { _classes } from "@scripts/utilities/helpers";
import useAppState from "../../../scripts/providers/AppProvider";
import { smallClearIcon, bigClearIcon } from "../../widgets/SVG";
import { Link, Text, Modal } from "../../widgets";

const cl = _classes(styles);

const CookiePopup = () => {
  const [visible, setVisibility] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];
  const [privacyModalOpen, togglePrivacyModal] = useState(false);
  const breakpoint = 768;
  const privacyPage = byTemplate("privacy_policy")[0];

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const [cookiesAllowed, setCookiesAllowed] = useState(false);

  useEffect(() => {
    const sessionCookie = localStorage.getItem("cookiesAccepted");
    const expirationDate = getLocalStorageCookieDate();
    const now = new Date();

    if (sessionCookie && expirationDate >= now) {
      saveCookiePreference();
    }
  }, []);

  const enableGoogleConsent = () => {
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted",
      });
    }
  };
  const getLocalStorageCookieDate = () => {
    const expiration = localStorage.getItem("cookiesAcceptedExpiration");
    return new Date(expiration);
  };

  const saveCookiePreference = () => {
    enableGoogleConsent();
    setCookiesAllowed(true);
    setCookieAcceptedLocalStorage();
  };
  const setCookieAcceptedLocalStorage = () => {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    localStorage.setItem("cookiesAccepted", true);
    localStorage.setItem("cookiesAcceptedExpiration", expiration.toISOString());
  };

  const declineGoogleConsent = () => {
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "declined",
        ad_user_data: "declined",
        ad_personalization: "declined",
        analytics_storage: "declined",
      });
    }
  };

  const setDeclineCookieLocalStorage = () => {
    declineGoogleConsent();
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    localStorage.setItem("cookiesDeclined", true);
    localStorage.setItem("cookiesDeclinedExpiration", expiration.toISOString());
    setCookiesAllowed(true);
  };

  if (cookiesAllowed) return null;
  return (
    <div
      className={`${styles.cookiePopup} ${
        visible ? styles.cookiePopup_visible : ""
      }`}
    >
      <div className={styles.cookiePopup__inner}>
        <div className={`${styles.cookiePopup__blurb}`}>
          We use technologies, such as cookies, to customize content and
          advertising, to provide social media features and to analyze traffic
          to the site. We also share information about your use of our site with
          our trusted social media, advertising and analytics partners.{" "}
          <span
            onClick={() => togglePrivacyModal(true)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            See Updated Cookie Notice
          </span>
          .
        </div>
        <div className={styles.cookiePopup_btn__wrapper}>
          <button
            className={styles.cookiePopup__button}
            onClick={saveCookiePreference}
          >
            Accept
          </button>
          <button
            className={styles.cookiePopup__button}
            onClick={setDeclineCookieLocalStorage}
          >
            Decline
          </button>
        </div>
      </div>

      <Modal
        open={privacyModalOpen}
        onClose={() => togglePrivacyModal(false)}
        modalClassname={cl("modal")}
      >
        <div>
          <Text
            text={privacyPage.h1}
            variant="h2"
            component="h2"
            className={cl("modal_title")}
          />
          <div
            dangerouslySetInnerHTML={{ __html: privacyPage.blurb1 }}
            className={cl("modal_blurb")}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CookiePopup;
