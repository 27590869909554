import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/roundButtons.scss";
import { directionArrow } from "../widgets/SVG";
import zenscroll from "zenscroll";

const cl = _classes(styles);

RoundButtons.propTypes = {
  heroCta: PropTypes.bool,
  direction: PropTypes.oneOf(["left", "right", "down", "up"]),
  link: PropTypes.string,
};

export default function RoundButtons({ heroCta, direction, link }) {
  const handleClick = () => {
    zenscroll.to(document.getElementById("intro"));
  };
  return (
    <div className={cl("conatiner")}>
      <button
        type="button"
        className={cl(["cta-wrapper", heroCta && "hero-cta"])}
        onClick={handleClick}
      >
        <div
          className={cl([
            "arrow-wrap",
            direction === "down" ? "down" : "right",
          ])}
        >
          {directionArrow}
        </div>
      </button>
    </div>
  );
}
