const APP_CONFIG = {
  loadingDuration: 0,
  hubspotFormID: "3a5ba0b2-c3de-4e90-ad20-26142341e26e",
  mapCategoryColors: {
    food_drinks: "#f7941d",
    shopping: "#5dcaed",
    landmarks_attractions: "#8a6344",
    entertainment: "#005f84",
    wellness: "#007e3d",
    everyday_necessities: "#e29573",
  },
  mapCategoryOptions: {
    food_drinks: "Food & Drinks",
    shopping: "Shopping",
    landmarks_attractions: "Landmarks & Attractions",
    entertainment: "Entertainment",
    wellness: "Wellness",
    everyday_necessities: "Everyday Necessities",
  },
  nestio: {
    base_url: "https://nestiolistings.com/api/v2/appointments/group/",
  },
};

export default APP_CONFIG;
