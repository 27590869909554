Text.propTypes = {
  component: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "span",
    "label",
  ]),
  variant: PropTypes.oneOf(["h1", "h2", "h3", "body1", "body2", "body3"]),
  className: PropTypes.string,
  text: PropTypes.string,
  defaultMessage: PropTypes.string,
  font: PropTypes.oneOf(["light", "book", "medium", "bold", "black"]),
  htmlFor: PropTypes.string,
  id: PropTypes.string,
};
export default function Text({
  children,
  component = "span",
  variant = "",
  className = "",
  text = "",
  font = "",
  htmlFor,
  id = "",
}) {
  const classes = ["root", variant, font, className].join(" ");

  const content = () => (children ? children : text);

  return React.createElement(
    component,
    {
      className: classes,
      htmlFor,
      id,
    },
    content()
  );
}
