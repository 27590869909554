import React from "react";
import { _classes } from "../../utilities/helpers";
import styles from "./bannerForm.scss";
import { FormProvider, StandardInput } from "..";
import { Button, Text } from "../../widgets";
const cl = _classes(styles);

const BannerForm = ({ className }) => {
  return (
    <FormProvider onSubmit={"newsletter"} className={`${cl("_")} ${className}`}>
      <Text
        text={"Stay ahead with the latest updates and news about The Refinery"}
        variant="p"
        component="p"
      />
      <div className={cl('input-wrapper')}>
        <StandardInput
          className={cl("field")}
          type="text"
          placeholder="Email"
          name="email"
          labelVisible
          label="Email"
          rules={{ required: "Email required" }}
        />
        <Button text={"SUBMIT"} />
      </div>
    </FormProvider>
  );
};

export default BannerForm;
