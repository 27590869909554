import { _classes } from "@scripts/utilities/helpers";
import styles from "./BuildingTextAndIcons.scss";
import { Container, Text } from "@scripts/widgets";
import { grass, building, window } from "@scripts/widgets/SVG";

const ICONS = { grass, building, window };

const cl = _classes(styles);

BuildingTextAndIcons.propTypes = {
  items: PropTypes.array,
};

export default function BuildingTextAndIcons({ items }) {
  return (
    <section className={cl("root")}>
      <Container>
        <ul className={cl("list")}>
          {items?.map((item) => (
            <li key={item.id} className={cl("item")}>
              <div className={cl("icon")}>{ICONS[item.h2]}</div>
              <Text text={item.h1} variant="h2" component="h3" />
              <Text text={item.blurb1} variant="body1" component="p" />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
