import { _classes } from "@scripts/utilities/helpers";
import styles from "./OfficesIntro.scss";
import { Container, Text } from "@scripts/widgets";
import Wysiwyg from "../../../widgets/Wysiwyg/Wysiwyg";

const cl = _classes(styles);

OfficesIntro.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  blurb: PropTypes.string,
  intro: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default function OfficesIntro({ title, subtitle, blurb, cta, intro,className }) {
  return (
    <section className={`${cl(["root", className])}`}>
      <Container className={cl("container")} extraPadding>
        <div className={cl("inner")}>
          <div className={cl("left")}>
            {title && (
              <Text component="h1">
                <Text
                  text={title.split(" ")[0]}
                  variant="h2"
                  className={cl(["text", "subtitle"])}
                />
                <br />
                <Text
                  text={title.split(" ")[1]}
                  variant="h1"
                  className={cl(["text", "title"])}
                />
              </Text>
            )}
            <Text
              text={subtitle}
              variant="h2"
              component="h2"
              font="light"
              className={cl("subtitle")}
            />
            <div className={cl("intro")}>
              <Wysiwyg html={intro} />
            </div>
          </div>

          <div className={cl("right")}>
            <Wysiwyg html={blurb} />
          </div>
        </div>
      </Container>
    </section>
  );
}
