import { _classes, parse } from "@scripts/utilities/helpers";
import styles from "./DownloadsList.scss";
import { Container, Text, CTA, Wysiwyg } from "@scripts/widgets";

const cl = _classes(styles);

DownloadsList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  description: PropTypes.string,
};

export default function DownloadsList({ title, description, items = [] }) {
  return (
    <section className={cl("root")}>
      <Container className={cl("container")} extraPadding>
        <Text
          text={title}
          variant="h1"
          component="h1"
          className={cl(["text", "title"])}
        />

        <ul className={cl("list")}>
          {items.map((item) => {
            const cta = parse(item.buttonlink1, {});
            return (
              <li className={cl("item")} key={item.id}>
                <CTA text={cta.text} link={cta.link} fullWidth />
              </li>
            );
          })}
        </ul>

        <Wysiwyg html={description} />
      </Container>
    </section>
  );
}
