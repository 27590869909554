import { _classes } from "@scripts/utilities/helpers";
import styles from "./TextBlock.scss";
import { Container, Text } from "@scripts/widgets";

const cl = _classes(styles);

TextBlock.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  subtitle2: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.oneOf([
    "white",
    "darkGrey",
    "darkerGrey",
    "offWhite",
  ]),
  textColor: PropTypes.oneOf(["light", "dark"]),
  isPageTitle: PropTypes.bool,
};

export default function TextBlock({
  subtitle,
  title,
  subtitle2,
  text,
  backgroundColor = "white",
  textColor = "dark",
  isPageTitle = false,
}) {
  return (
    <section className={cl(["root", textColor, backgroundColor])}>
      <Container>
        <div className={cl("inner")}>
          <Text component={isPageTitle ? "h1" : "h2"}>
            <Text
              text={subtitle}
              variant="h2"
              className={cl(["text", "subtitle"])}
            />
            <br />
            <Text text={title} variant="h1" className={cl(["text", "title"])} />
          </Text>
          {subtitle2 && (
            <div
              dangerouslySetInnerHTML={{ __html: subtitle2 }}
              className={cl(["text", "subtitle2"])}
            />
          )}
          {text && (
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className={cl(["text", "content"])}
            />
          )}
        </div>
      </Container>
    </section>
  );
}
