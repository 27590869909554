export const calendarArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
  </svg>
);

export const directionArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.16 20.59">
    <path
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="2px"
      d="m12.16.71 9.59 9.59-9.59 9.59M21.75 10.3H0"
    />
  </svg>
);

export const refineryLogo = (
  <svg
    viewBox="387.878 572.672 2816.98 663.308"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="matrix(0.1, 0, 0, -0.1, 0, 1801.000061)"
      fill="#000000"
      stroke="none"
    >
      <path d="M6012 10443 l3 -1758 388 -3 387 -2 0 565 0 565 303 0 303 0 378 -565 378 -565 450 0 c403 0 450 2 444 16 -5 12 -809 1194 -839 1231 -7 9 4 18 39 33 479 201 724 599 701 1139 -9 210 -43 346 -129 512 -149 288 -427 477 -824 561 -109 23 -113 23 -1047 30 l-937 3 2 -1758z m1783 1033 c144 -43 256 -126 312 -233 39 -73 53 -141 53 -253 0 -198 -90 -343 -263 -426 -128 -60 -161 -64 -659 -64 l-448 0 0 501 0 500 473 -4 c414 -3 479 -6 532 -21z" />
      <path d="M9642 10443 l3 -1758 1338 -3 1337 -2 0 350 0 350 -950 0 -950 0 0 365 0 365 825 0 825 0 0 345 0 345 -825 0 -825 0 0 355 0 355 940 0 940 0 0 345 0 345 -1330 0 -1330 0 2 -1757z" />
      <path d="M13010 10440 l0 -1760 388 2 387 3 3 678 2 677 835 0 835 0 0 355 0 355 -835 0 -835 0 0 375 0 375 950 0 950 0 0 350 0 350 -1340 0 -1340 0 0 -1760z" />
      <path d="M17990 10440 l0 -1760 383 2 382 3 5 1109 5 1109 810 -1064 c446 -585 827 -1085 848 -1111 l38 -48 327 2 327 3 3 1758 2 1757 -385 0 -385 0 -2 -1076 -3 -1077 -819 1077 -819 1076 -359 0 -358 0 0 -1760z" />
      <path d="M21952 10443 l3 -1758 1338 -3 1337 -2 0 350 0 350 -950 0 -950 0 0 365 0 365 825 0 825 0 0 345 0 345 -825 0 -825 0 0 355 0 355 940 0 940 0 0 345 0 345 -1330 0 -1330 0 2 -1757z" />
      <path d="M25320 10440 l0 -1760 388 2 387 3 3 563 2 562 299 0 300 0 367 -547 c201 -302 373 -556 382 -565 14 -17 49 -18 459 -18 315 0 443 3 443 11 0 6 -189 287 -420 625 -231 337 -420 617 -420 621 0 5 31 20 69 36 119 48 247 134 351 237 150 148 232 290 287 498 21 80 26 127 30 274 6 195 -4 290 -43 426 -128 442 -487 714 -1034 781 -53 7 -410 11 -967 11 l-883 0 0 -1760z m1775 1037 c215 -62 338 -194 366 -391 33 -240 -70 -440 -271 -529 -121 -53 -163 -57 -644 -57 l-446 0 0 501 0 500 468 -3 c404 -4 475 -6 527 -21z" />
      <path d="M28504 12133 c24 -38 326 -513 672 -1058 l629 -990 5 -700 5 -700 385 0 385 0 3 702 2 702 666 1043 c367 574 671 1049 676 1056 8 9 -80 12 -433 12 l-444 0 -415 -687 c-228 -377 -420 -693 -426 -701 -8 -11 -98 133 -426 684 l-415 699 -456 3 -457 2 44 -67z" />
      <path d="M16293 12172 c-28 -22 -45 -66 -37 -98 9 -36 60 -74 100 -74 l34 0 0 -130 0 -130 347 0 346 0 -6 129 -6 129 50 4 c62 6 88 30 96 86 5 36 2 45 -25 72 l-30 30 -424 0 c-385 0 -425 -2 -445 -18z" />
      <path d="M5050 11995 l0 -185 -492 -2 -493 -3 -3 -137 -3 -138 496 0 495 0 0 -190 0 -191 128 3 127 3 3 513 2 512 -130 0 -130 0 0 -185z" />
      <path d="M16377 11498 c-4 -62 -7 -137 -7 -166 l0 -52 363 2 362 3 -2 55 c-1 30 -5 103 -9 163 l-6 107 -347 0 -348 0 -6 -112z" />
      <path d="M16366 11128 c-3 -7 -7 -91 -10 -185 l-6 -173 380 0 380 0 0 124 c0 67 -3 151 -6 185 l-7 61 -364 0 c-282 0 -364 -3 -367 -12z" />
      <path d="M4067 10943 c-4 -3 -7 -66 -7 -140 l0 -133 250 0 250 0 0 -250 0 -250 -250 0 -250 0 0 -140 0 -140 623 2 622 3 0 135 0 135 -242 3 -243 2 0 250 0 250 245 0 246 0 -3 138 -3 137 -616 3 c-338 1 -619 -1 -622 -5z" />
      <path d="M16347 10498 c-4 -66 -7 -143 -7 -170 l0 -48 396 0 396 0 -7 148 c-3 81 -8 157 -11 170 l-4 22 -379 0 -378 0 -6 -122z" />
      <path d="M16336 10068 c-5 -54 -57 -1332 -56 -1380 0 -17 28 -18 449 -18 248 0 452 3 454 8 3 4 -6 304 -19 667 -13 363 -23 688 -24 723 l0 62 -399 0 -399 0 -6 -62z" />
      <path d="M4060 9125 l0 -475 125 0 125 0 0 335 0 335 125 0 125 0 0 -290 0 -290 125 0 125 0 0 290 0 290 125 0 125 0 0 -330 0 -330 125 0 125 0 -2 468 -3 467 -622 3 -623 2 0 -475z" />
      <path d="M17475 7216 c-141 -33 -254 -95 -353 -196 -132 -133 -192 -276 -200 -476 -5 -142 15 -243 72 -359 217 -439 848 -516 1187 -146 123 134 185 315 177 514 -9 193 -72 341 -203 472 -83 82 -169 136 -280 172 -99 32 -302 41 -400 19z m235 -217 c95 -15 181 -59 249 -127 98 -97 141 -207 141 -357 0 -298 -205 -499 -492 -483 -252 15 -430 217 -430 488 0 278 176 468 452 489 8 0 44 -4 80 -10z" />
      <path d="M25390 7210 c-389 -101 -618 -495 -515 -885 102 -384 507 -605 905 -494 241 67 422 249 492 494 17 62 22 106 22 195 0 194 -60 352 -184 483 -104 111 -209 173 -352 207 -110 26 -265 26 -368 0z m336 -230 c325 -112 421 -570 173 -818 -177 -177 -475 -177 -646 0 -61 63 -110 150 -127 228 -55 239 43 469 239 566 114 56 243 64 361 24z" />
      <path d="M10301 7198 c-5 -13 -140 -322 -300 -688 -160 -365 -291 -670 -291 -678 0 -10 27 -12 122 -10 l122 3 71 167 70 168 326 0 325 0 70 -167 69 -168 129 -3 c121 -2 128 -1 124 16 -3 10 -139 325 -303 700 l-298 682 -114 0 c-108 0 -114 -1 -122 -22z m231 -535 c59 -137 110 -256 113 -265 7 -17 -8 -18 -225 -18 -127 0 -230 4 -228 9 27 71 224 521 228 521 3 0 53 -111 112 -247z" />
      <path d="M11694 7197 c-2 -7 -3 -58 -2 -113 l3 -99 217 -3 218 -2 2 -578 3 -577 120 0 120 0 3 578 2 577 220 0 220 0 0 115 0 115 -560 0 c-455 0 -562 -2 -566 -13z" />
      <path d="M14845 7198 c-3 -7 -4 -319 -3 -693 l3 -680 235 -3 c478 -6 638 36 820 218 180 180 241 444 161 700 -58 186 -215 346 -409 417 -120 45 -195 52 -510 53 -225 0 -294 -3 -297 -12z m674 -237 c82 -26 138 -60 194 -120 202 -215 148 -596 -102 -729 -91 -49 -163 -62 -353 -62 l-168 0 0 465 0 465 184 0 c152 0 195 -3 245 -19z" />
      <path d="M19245 7198 c-3 -7 -4 -319 -3 -693 l3 -680 120 0 120 0 5 484 5 484 208 -312 c114 -171 212 -311 217 -311 5 0 107 145 225 322 l215 323 0 -498 0 -497 120 0 120 0 0 695 0 695 -128 0 -129 0 -156 -242 c-85 -134 -180 -280 -210 -326 l-54 -83 -209 325 -209 326 -128 0 c-93 0 -129 -3 -132 -12z" />
      <path d="M21562 6518 l3 -693 120 0 120 0 0 690 0 690 -123 3 -122 3 2 -693z" />
      <path d="M22765 7198 c-3 -7 -4 -319 -3 -693 l3 -680 120 0 120 0 5 483 5 482 375 -482 375 -483 103 -3 102 -3 0 696 0 695 -120 0 -120 0 -2 -474 -3 -475 -366 472 -365 472 -112 3 c-84 2 -114 -1 -117 -10z" />
    </g>
  </svg>
);

export const facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
  </svg>
);

export const instagram = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
  </svg>
);

export const window = (
  <svg
    viewBox="140.579 -125.564 298.387 564.487"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1, 0, 0, 1, 145.141543, -123.081652)">
      <g>
        <line
          class="st0"
          x1="33.6"
          y1="54.8"
          x2="33.6"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="65.1"
          y1="25.9"
          x2="65.1"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="191.4"
          y1="10.1"
          x2="191.4"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="128.3"
          y1="2.9"
          x2="128.3"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="159.8"
          y1="2.9"
          x2="159.8"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="96.7"
          y1="10.1"
          x2="96.7"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="223"
          y1="25.9"
          x2="223"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="254.5"
          y1="54.8"
          x2="254.5"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="151.4"
          x2="2"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="286.1"
          y1="151.4"
          x2="286.1"
          y2="556.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
      </g>
      <g>
        <line
          class="st0"
          x1="14.9"
          y1="84.7"
          x2="273.2"
          y2="84.7"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="4.4"
          y1="118.1"
          x2="283.7"
          y2="118.1"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="151.4"
          x2="286.1"
          y2="151.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="184.8"
          x2="286.1"
          y2="184.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="218.1"
          x2="286.1"
          y2="218.1"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="251.5"
          x2="286.1"
          y2="251.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="284.8"
          x2="286.1"
          y2="284.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="318.2"
          x2="286.1"
          y2="318.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="351.5"
          x2="286.1"
          y2="351.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="384.9"
          x2="286.1"
          y2="384.9"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="425.8"
          x2="286.1"
          y2="425.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="466.4"
          x2="286.1"
          y2="466.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <line
          class="st0"
          x1="2"
          y1="507"
          x2="286.1"
          y2="507"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
      </g>
      <path
        class="st0"
        d="M286.1,556.8H2V144.1C2,65.6,65.6,2,144.1,2h0c78.5,0,142.1,63.6,142.1,142.1V556.8z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "4px",
        }}
      />
    </g>
  </svg>
);

export const building = (
  <svg
    viewBox="102.365 -162.353 433.502 665.283"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1, 0, 0, 1, 108.438303, -158.615893)">
      <polygon
        class="st0"
        points="185,273.8 185,367 1.5,367 1.5,655.6 185,655.6 229.2,655.6 369.5,655.6 369.5,273.8 &#9;"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <polygon
        class="st0"
        points="185,273.8 185,367 1.5,367 1.5,655.6 185,655.6 229.2,655.6 369.5,655.6 369.5,273.8 &#9;"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <g>
        <path
          class="st0"
          d="M170.7,432.1h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M170.7,483h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V483z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M170.7,534h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V534z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M170.7,584.9h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <rect
          x="136.9"
          y="379.6"
          class="st0"
          width="48.1"
          height="226.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
      </g>
      <g>
        <line
          class="st0"
          x1="380.6"
          y1="44.2"
          x2="407.6"
          y2="44.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="379.9"
          y1="64.2"
          x2="408.3"
          y2="64.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="379.3"
          y1="84.3"
          x2="408.9"
          y2="84.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="378.6"
          y1="104.3"
          x2="409.6"
          y2="104.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="377.9"
          y1="124.4"
          x2="410.2"
          y2="124.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="376.6"
          y1="164.5"
          x2="411.6"
          y2="164.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="376"
          y1="184.6"
          x2="412.2"
          y2="184.6"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <line
          class="st0"
          x1="377.3"
          y1="144.5"
          x2="410.9"
          y2="144.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
      </g>
      <rect
        x="369.5"
        y="210.3"
        class="st0"
        width="49.2"
        height="445.3"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M413.1,210.3l-6.5-197.9c2.6-0.5,4.5-2.7,4.5-5.4c0-3-2.5-5.5-5.5-5.5h-23c-3,0-5.5,2.5-5.5,5.5 c0,2.7,1.9,4.9,4.5,5.4l-6.5,197.9H413.1z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M266.6,330.3h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V330.3z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M307.9,330.3h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V330.3z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M266.6,381.2h-19.5V367c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V381.2z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M307.9,381.2h-19.5V367c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V381.2z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M266.6,432.1h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M307.9,432.1h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M266.6,483h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V483z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M307.9,483h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V483z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M266.6,534h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V534z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M307.9,534h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V534z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M266.6,584.9h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M307.9,584.9h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7l0,0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M85.2,432.1H65.7v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M120.8,432.1h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M85.2,483H65.7v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V483z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M120.8,483h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V483z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M85.2,534H65.7v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V534z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M120.8,534h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V534z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M85.2,584.9H65.7v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M120.8,584.9h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <g>
        <path
          class="st0"
          d="M51,655.6H22.7v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M100.6,655.6H72.3v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M150.1,655.6h-28.3v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M199.7,655.6h-28.3v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M249.2,655.6h-28.3v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M298.7,655.6h-28.3v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
        <path
          class="st0"
          d="M348.3,655.6H320v-20.6c0-7.8,6.3-14.1,14.1-14.1h0c7.8,0,14.1,6.3,14.1,14.1V655.6z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
      </g>
      <rect
        x="1.5"
        y="367"
        class="st0"
        width="183.5"
        height="12.7"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <rect
        x="185"
        y="273.8"
        class="st0"
        width="184.5"
        height="12.7"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M35.3,432.1H15.8v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M35.3,483H15.8v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V483z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M35.3,534H15.8v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V534z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <path
        class="st0"
        d="M35.3,584.9H15.8v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <rect
        x="1.5"
        y="379.6"
        class="st0"
        width="48.1"
        height="226.2"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <g>
        <g>
          <path
            class="st0"
            d="M218.7,330.3h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V330.3z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M218.7,381.2h-19.5V367c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V381.2z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M218.7,432.1h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M218.7,483h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V483z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M218.7,534h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V534z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M218.7,584.9h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
        </g>
        <rect
          x="185"
          y="286.5"
          class="st0"
          width="48.1"
          height="319.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
      </g>
      <g>
        <g>
          <path
            class="st0"
            d="M355.2,330.3h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V330.3z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M355.2,381.2h-19.5V367c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V381.2z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M355.2,432.1h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V432.1z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M355.2,483h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V483z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M355.2,534h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V534z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
          <path
            class="st0"
            d="M355.2,584.9h-19.5v-14.2c0-5.4,4.4-9.7,9.7-9.7h0c5.4,0,9.7,4.4,9.7,9.7V584.9z"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "10",
              strokeWidth: "3px",
            }}
          />
        </g>
        <rect
          x="321.5"
          y="286.5"
          class="st0"
          width="48.1"
          height="319.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "3px",
          }}
        />
      </g>
      <line
        class="st0"
        x1="1.5"
        y1="605.9"
        x2="418.7"
        y2="605.9"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <rect
        x="369.5"
        y="210.3"
        class="st0"
        width="49.2"
        height="22.7"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <rect
        x="369.5"
        y="336.8"
        class="st0"
        width="49.2"
        height="22.7"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <rect
        x="380.9"
        y="248.9"
        class="st0"
        width="26.3"
        height="71.9"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
      <rect
        x="380.9"
        y="381"
        class="st0"
        width="26.3"
        height="131.6"
        style={{
          fill: "none",
          stroke: "rgb(0, 0, 0)",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: "10",
          strokeWidth: "3px",
        }}
      />
    </g>
  </svg>
);

export const grass = (
  <svg
    viewBox="270.759 -74.686 412.314 394.006"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1, 0, 0, 1, 276.897343, -63.478501)">
      <g>
        <path
          class="st0"
          d="M92.5,214.1c0,0-28.1-8.4-25.8-39c30.6-1.5,39.2,26.8,39.2,26.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M150.3,156c0,0-32.6,0.4-41.2-29.1c28.2-12.1,48.6,11.4,48.6,11.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M135.2,176.2c0,0,28.8,9.4,26.5,40c-30.6,1.5-39.8-27.6-39.8-27.6"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M71.7,239.8c0,0,29.2-1.8,37.7,27.7c-28.2,12-46.3-12.2-46.3-12.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M49.5,311.5c0,0-31.7,9.8-47.5-16.6c24.2-18.8,48.8-0.9,48.8-0.9"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M161.3,83.9c0,0,25.2-16.6,47.4,4.6c-18.3,24.6-45.9,13.4-45.9,13.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M142.7,44.9c0,0-13.6,28.8-43.9,23.6c1.5-30.6,33.7-38.1,33.7-38.1"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M72.6,374.1c0,0-41.7-42.3-12.9-111.1c31.7-75.7,93.4-68.4,102.1-145.5C170,44.3,106,2,106,2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
      </g>
      <g>
        <path
          class="st0"
          d="M228.3,222.3c0,0,22.6-18.6,46.8,0.3c-15.8,26.3-44.1,17.6-44.1,17.6"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M219.6,119.2c0,0,30.5-11.7,48.7,13c-22.2,21.1-49.5,6.2-49.5,6.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M217.1,180.1c0,0-24.6,17.6-47.9-2.4c17-25.5,45.8-15.7,45.8-15.7"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M225.2,290.9c0,0-27.1,11.2-44.6-14c22.8-20.5,47.8-3.5,47.8-3.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M216,330.7c0,0,32.8,5.4,35,36.1c-30.1,6-44-21-44-21"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M160.9,371.9c0,0-23.7-23.3-7.9-49.7c28.1,12.4,23.8,42.5,23.8,42.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M277.7,17.8c0,0,29.4,7.1,28.9,37.8c-30.5,3.4-41.1-24.4-41.1-24.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M236.8,67.3c0,0-31.7-3.3-36.7-33.6C229.4,25,247.2,53,247.2,53"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M144.9,374.1c0,0,58,5.9,78.4-66c19.4-68.2-7-90.3-7-168.4C216.3,66.1,292.8,2,292.8,2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
      </g>
      <g>
        <path
          class="st0"
          d="M349.4,209c0,0,16.3-24.3,44.9-13.1c-7.6,29.7-37.1,29.6-37.1,29.6"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M315.3,101.8c0,0,30.5-11.7,48.7,13c-22.2,21.1-49.5,6.2-49.5,6.2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M326.4,179.5c0,0-17.2,24.9-45.9,14c7.3-29.8,37.8-30.3,37.8-30.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M359.3,289.3c0,0-26.8,11.9-44.9-12.9c22.3-21.1,47.7-4.7,47.7-4.7"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M348.2,326.7c0,0,32.8,5.4,35,36.1c-30.1,6-44-21-44-21"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M298.1,368.5c0,0-25.2-21.7-11.2-49c28.8,10.5,26.6,40.8,26.6,40.8"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M356.4,23c0,0,29.4,7,29.1,37.8c-30.5,3.5-41.2-24.3-41.2-24.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
        <path
          class="st0"
          d="M280.6,374.1c0,0,72.2-7.6,82.4-97.5c8-70.4-54.6-86.1-49.4-164.1C319.5,23.4,390.4,2,390.4,2"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            strokeWidth: "4px",
          }}
        />
      </g>
    </g>
  </svg>
);

export const dominoBuildingFloors = (
  <svg
    viewBox="128.079 52.363 438.523 614.004"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Cellar"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="16.9"
        y="559.4"
        class="st1"
        width="388.7"
        height="43.3"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x31_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="519.6"
        class="st1"
        width="248.7"
        height="39.7"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x32_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="484.5"
        class="st1"
        width="248.7"
        height="35.1"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x33_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="449.4"
        class="st1"
        width="248.7"
        height="35.1"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x34_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="414.3"
        class="st1"
        width="248.7"
        height="35.1"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x35_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="385.3"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x36_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="356.4"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x37_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="328.4"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x38_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="300"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x39_F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="271.5"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x31_0F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="243.5"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x31_1F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <rect
        x="75.2"
        y="214.6"
        class="st1"
        width="248.7"
        height="29"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x31_2F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <path
        class="st1"
        d="M77.8,185.7c-1.7,8.3-2.7,16.9-2.7,25.7v3.2h248.7v-3.2c0-8.8-0.9-17.4-2.7-25.7H77.8z"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="_x31_4F"
      class="st0"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
      style={{ display: "none" }}
    >
      <path
        class="st1"
        d="M77.6,186.7h243.7c-2.1-10.2-5.3-19.9-9.7-29H87.3C83,166.8,79.7,176.5,77.6,186.7z"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g id="_x31_5F" transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)">
      <path
        class="st2"
        d="M87.4,157.5h224.1c-19.5-40.5-60.4-68.8-108-70.3h-8.1C147.8,88.7,107,117,87.4,157.5z"
        style={{ fill: "rgb(254, 218, 0)" }}
      />
    </g>
    <g
      id="Structure"
      transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
    >
      <g>
        <path
          class="st3"
          d="M199.5,87.1L199.5,87.1c-68.7,0-124.3,55.7-124.3,124.3v348h248.7v-348C323.8,142.8,268.2,87.1,199.5,87.1z"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <rect
          x="31.5"
          y="312.5"
          class="st4"
          width="16"
          height="246.9"
          style={{
            fill: "none",
            stroke: "rgb(128, 130, 133)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <rect
          x="348.4"
          y="180.6"
          class="st4"
          width="41.6"
          height="378.7"
          style={{
            fill: "none",
            stroke: "rgb(128, 130, 133)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <polygon
          class="st4"
          points="390,180.6 348.4,180.6 357.1,1 381.3,1 &#9;&#9;"
          style={{
            fill: "none",
            stroke: "rgb(128, 130, 133)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <rect
          x="16.9"
          y="559.4"
          class="st3"
          width="388.7"
          height="43.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="0"
          y1="559.4"
          x2="425.4"
          y2="559.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="87.7"
          y1="157.7"
          x2="311.2"
          y2="157.7"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="78.4"
          y1="186.1"
          x2="320.6"
          y2="186.1"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="214.6"
          x2="323.8"
          y2="214.6"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="243.1"
          x2="323.8"
          y2="243.1"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="271.5"
          x2="323.8"
          y2="271.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="300"
          x2="323.8"
          y2="300"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="328.4"
          x2="323.8"
          y2="328.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="356.9"
          x2="323.8"
          y2="356.9"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="385.3"
          x2="323.8"
          y2="385.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="414.3"
          x2="323.8"
          y2="414.3"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="484.5"
          x2="323.8"
          y2="484.5"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="449.4"
          x2="323.8"
          y2="449.4"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
        <line
          class="st3"
          x1="75.1"
          y1="519.6"
          x2="323.8"
          y2="519.6"
          style={{
            fill: "none",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: "10",
            strokeWidth: "2px",
          }}
        />
      </g>
    </g>
  </svg>
);

export const checkmark = (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 24 24"
  >
    <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
  </svg>
);

export const subway = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.7 17.7"
    width="56"
    height="56"
  >
    <path d="M8.9 0C4 0 0 4 0 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9C17.7 4 13.8 0 8.9 0z" />
    <path
      d="M6.2 13.3c-.3-.1-.5-.1-.7-.2-.8-.4-1.2-1-1.2-1.9V5.8c0-1 .4-1.6 1.3-2 .1-.1.2-.1.4-.2.6-.1 1.2-.2 1.7-.2h1.6c.9 0 1.7 0 2.6.3.3.1.6.2.8.4.6.3.8.9.8 1.5v5.7c0 .9-.8 1.8-1.7 1.9h-.3c.2.2.5.4.7.7l.1.1v.3h-7c0-.2 0-.3.1-.4.4-.2.6-.4.8-.6zm6.1-4.8V5.8c0-.1-.1-.1-.2-.1H9.6c-.1 0-.2 0-.2.2v2.5c0 .2.1.2.2.2h2.5c.1 0 .1 0 .2-.1zm-5.4.1h1.3c.1 0 .1 0 .1-.1V5.9c0-.1 0-.1-.1-.1H5.6c-.1 0-.1 0-.1.2v2.5c0 .1 0 .2.2.2.3-.1.7-.1 1.2-.1zm3.7 2.6c0 .5.4.9.8.9s.8-.4.8-.8c0-.5-.4-.8-.9-.8-.3-.2-.7.2-.7.7zm-5.2 0c0 .5.4.8.8.9.4 0 .9-.4.9-.8s-.4-.8-.9-.8c-.4-.2-.8.2-.8.7z"
      fill="#fff"
    />
  </svg>
);

export const bus = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.7 17.7"
    width="56"
    height="56"
  >
    <path d="M9 0C4.1 0 .1 4 .1 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9C17.8 4 13.8 0 9 0z" />
    <path
      d="M13.7 13.2h-.6c-.2 0-.2.1-.2.2v.6c0 .4-.3.6-.7.6-.4 0-.7-.2-.7-.6v-.7c0-.1-.1-.2-.2-.2H6.5v.7c0 .5-.3.7-.7.7-.4 0-.7-.3-.7-.7v-.7h-.7v-.3c0-1.3-.1-2.7 0-4 0-1.1.2-2.2.3-3.4 0-.1.1-.3.1-.4 0-.8.5-1.2 1.2-1.4 1.5-.5 3.1-.7 4.6-.4.6.1 1.1.3 1.7.5.7.2 1 .7 1.1 1.4.1 1 .3 2 .4 3v1.3c-.1 1.2-.1 2.5-.1 3.8zM9 8.6h3.3c.5 0 .5-.1.5-.5l-.3-2.4c-.1-.4-.2-.5-.5-.5H6.1c-.3 0-.5.1-.5.4-.2.9-.3 1.7-.4 2.6 0 .4 0 .5.4.5 1.1-.1 2.2-.1 3.4-.1zm0-4.1h1.9c.2 0 .3 0 .3-.2-.1-.2-.2-.3-.4-.3H7.1c-.1 0-.3.1-.3.3 0 .2.2.2.3.2H9zm3.2 7.1c.4 0 .6-.2.6-.6 0-.3-.2-.6-.6-.6-.4 0-.6.3-.6.6s.2.6.6.6zm-6.5-1.2c-.4 0-.6.2-.6.6 0 .3.2.6.6.6.3 0 .6-.3.6-.6s-.2-.6-.6-.6z"
      fill="#fff"
    />
  </svg>
);

export const bike = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.7 17.7"
    width="56"
    height="56"
  >
    <path d="M8.9.1C4 .1 0 4 0 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9C17.7 4 13.8.1 8.9.1z" />
    <path
      d="M13 7.3c-.5 0-1 .2-1.4.4l-.8-1.1.4-.8v-.3c-.1-.1-.2-.1-.3-.1H9.7c-.2 0-.3.1-.3.3 0 .1.2.3.3.3h.6l-.2.4H6.7L7.4 5h.7c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-.9c-.1 0-.2 0-.3.1L5.6 7.4c-.3-.1-.5-.1-.8-.1-1.5 0-2.7 1.2-2.7 2.7 0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1-.5-1.8-1.3-2.3l.2-.4 2.2 2.9s.1.1.2.1h1.5c.2 1.4 1.3 2.4 2.7 2.4 1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7zM6.9 10c0 1.2-.9 2.1-2.1 2.1-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1.2 0 .4 0 .6.1l-.9 1.9c-.1.1 0 .3.1.4.2.1.3 0 .4-.1l.9-1.9c.6.3 1 1 1 1.7zm5.5-.3h-1.5c.1-.4.3-.8.6-1.2l.9 1.2zm-2.1 0h-1l1.2-2.5.7.8c-.6.5-.8 1.1-.9 1.7zM6.9 7h3L8.7 9.4 6.9 7zm6.1 5.1c-1.1 0-1.9-.8-2.1-1.8H13c.1 0 .2-.1.3-.2v-.3L12 8.2c.2-.2.6-.3 1-.3 1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1z"
      fill="#fff"
    />
  </svg>
);

export const ferry = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.1 21.1"
    width="56"
    height="56"
  >
    <path d="M9.8 0c1.7-.1 3.5.2 5.1.9 1.6.7 2.9 1.8 4 3.2 1 1.4 1.7 3 2 4.7.4 2.2 0 4.5-1 6.4-.7-.5-1.5-.9-2.3-1.2-.4-.1-.9-.2-1.3-.3-1.1-.2-2.2-.1-3.2.1-.9.2-1.7.5-2.5.9-.1 0-.1.1-.2.1-.6-.5-1.4-.8-2.2-.9-1.2-.3-2.6-.3-3.8-.1-1.2.2-2.3.7-3.2 1.4-.8-1.5-1.2-3.3-1.1-5 0-1.8.6-3.6 1.5-5.1.8-1.4 2-2.6 3.4-3.5C6.3.6 8.1.1 9.8 0M8.4 4.2v.7l.1.1h4l.1-.1v-.8h-.1l-4.1.1zM6.3 5.9v2l.1.1c1.4-.3 2.7-.5 4.1-.8h.3c1.2.3 2.5.6 3.8.8l.1-.1V6c0-.1 0-.2-.1-.2-2.7 0-5.5 0-8.3.1zm3.9 2.3c-1.9.4-3.8.8-5.7 1.1h-.1l-.1.2c.3 1 .6 2 .9 3.1.9-.1 1.8 0 2.7.1.9.2 1.7.4 2.6.8h.1c1.6-.7 3.5-1.1 5.3-.9.3-1 .6-2 .9-3.1v-.1l-.2-.1-6-1.2c-.2.1-.3.1-.4.1" />
    <path d="M4.6 14.8c1.1-.2 2.2-.2 3.3.1.9.2 1.8.6 2.5 1.1h.1c.7-.4 1.4-.8 2.2-1 1.9-.5 3.9-.4 5.7.4.4.2.7.4 1.1.6-.2.3-.4.6-.6.8-.7-.5-1.5-.8-2.4-1-2.1-.5-4.3 0-6 1.3h-.1c-.6-.5-1.3-.8-2-1.1-.9-.3-1.8-.4-2.8-.4-1.2.1-2.5.5-3.5 1.2-.2-.3-.4-.6-.6-.8 1-.6 2-1 3.1-1.2" />
    <path d="M5.2 16.7c.5-.1 1-.1 1.5 0 .5 0 1 .2 1.5.3.8.3 1.5.8 2.1 1.2h.2c.6-.5 1.3-.9 2.1-1.2.5-.2 1-.3 1.5-.4.5 0 1.1-.1 1.6 0 .9.1 1.7.4 2.5.9-1.1 1.2-2.5 2.2-4.1 2.7-1.9.7-4.1.9-6.1.4S4.1 19 2.7 17.5c.8-.4 1.7-.7 2.5-.8" />
  </svg>
);

export const foot = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.7 17.7"
    width="56"
    height="56"
  >
    <path d="M8.9 0C4 0 0 4 0 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9C17.7 4 13.8 0 8.9 0z" />
    <g>
      <path
        d="M12.1 11.9c-.7-.2-1.3-.4-2-.6.1-.5.1-.9 0-1.4-.4-1.2-.3-2.3.2-3.4.2-.2.4-.5.7-.8.3-.3.6-.4 1-.4.6 0 1.1.3 1.3.9.1.4.2.9.2 1.3.1 1.2-.3 2.3-.8 3.4-.2.3-.4.6-.6 1zM7.3 8.8c-.3.1-.7.2-1.1.3-.1 0-.2 0-.3.1-.5.1-.5.1-.7-.4-.4-1-.8-2-.9-3 0-.6 0-1.3.1-1.9.1-.6.4-1.1 1-1.2.6-.2 1.2.1 1.6.6.7.9.9 2 .8 3.2-.1.4-.3.8-.4 1.2 0 .3 0 .7-.1 1.1zM7.6 9.5c.3.6.6 1.2.6 1.9 0 .5-.3 1-.7 1.1-.4.1-.9 0-1.2-.5-.4-.5-.5-1.1-.6-1.7 0-.1.1-.2.1-.2l1.8-.6zM9.9 12c.6.2 1.2.4 1.8.7.1 0 .1.1.1.2-.1.6-.3 1.2-.7 1.7-.3.4-.8.6-1.2.4-.4-.2-.7-.7-.7-1.2 0-.6.3-1.2.7-1.8z"
        fill="#fff"
      />
    </g>
  </svg>
);

export const jLine = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width="30"
    height="30"
  >
    <circle cx="12.5" cy="12.5" r="12" fill="#996632" />
    <path
      fill="#FFF"
      d="M14.1 8.9v4.7c0 .6-.1 1.1-.3 1.5-.4.6-1.3.7-2 .7-.4 0-.7-.1-.9-.1v-1.3c.3.1.5.1.8.1.3 0 .6-.1.7-.2.2-.2.2-.5.2-1V8.9h1.5z"
    />
  </svg>
);

export const zLine = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width="30"
    height="30"
  >
    <circle cx="12.5" cy="12.5" r="12" fill="#996632" />
    <path
      fill="#FFF"
      d="M15.1 15.7H9.9v-1.2l3.2-4.2H10V8.9h5.1V10l-3.2 4.3h3.3v1.4z"
    />
  </svg>
);

export const mLine = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width="30"
    height="30"
  >
    <circle cx="12.5" cy="12.5" r="12" fill="#FF6319" />
    <path
      fill="#FFF"
      d="m11.7 15.7-1-4c-.2-.6-.4-1.6-.4-1.6 0 .4.1 2.4.1 3.3v2.2H9V8.9h2.2l.9 3.4c.2.7.4 1.6.4 1.6s.2-.9.4-1.6l.9-3.4H16v6.8h-1.4v-2.2c0-1 .1-2.9.1-3.3 0 0-.2 1.1-.4 1.7l-1.1 3.8h-1.5z"
    />
  </svg>
);

export const lLine = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width="30"
    height="30"
  >
    <circle cx="12.5" cy="12.5" r="12" fill="#808183" />
    <path fill="#FFF" d="M11.9 8.9v5.4h2.7v1.4h-4.2V8.9h1.5z" />
  </svg>
);

export const gLine = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 125 125"
  >
    <circle cx="62.5" cy="62.5" r="50" fill="#6CBE45" />
    <path
      fill="#FFF"
      d="M84.1 50.7c-3.933-10-11.133-15-21.6-15-7.333 0-13.183 2.55-17.55 7.65S38.4 54.7 38.4 62.1c0 7.533 2.1 13.817 6.3 18.85 4.2 5.033 10.133 7.55 17.8 7.55 5.2 0 9.5-1.7 12.9-5.1v3.9H84V59.5H62.6v8.8h12.3c-.067 3.2-1.317 5.833-3.75 7.9s-5.317 3.1-8.65 3.1c-4.533 0-7.967-1.683-10.3-5.05-2.333-3.367-3.5-7.417-3.5-12.15s1.167-8.8 3.5-12.2c2.333-3.4 5.767-5.1 10.3-5.1 6.533 0 10.633 3.2 12.3 9.6l9.3-3.7z"
    />
  </svg>
);

export const smallClearIcon = (
  <svg width="11px" height="11px">
    <path
      fill-rule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M9.577,10.848 L0.348,1.619 L0.860,1.107 L10.089,10.335 L9.577,10.848 Z"
    />
    <path
      fill-rule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M0.348,10.079 L9.577,0.850 L10.089,1.363 L0.860,10.592 L0.348,10.079 Z"
    />
  </svg>
);

export const bigClearIcon = (
  <svg width="29px" height="30px">
    <path
      fill-rule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M27.867,30.486 L0.299,3.042 L1.831,1.517 L29.399,28.961 L27.867,30.486 Z"
    />
    <path
      fill-rule="evenodd"
      fill="rgb(0, 0, 0)"
      d="M0.299,28.199 L27.867,0.755 L29.399,2.280 L1.831,29.723 L0.299,28.199 Z"
    />
  </svg>
);
