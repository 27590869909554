import Reveal from "@scripts/widgets/Reveal";

export default class HubspotContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    formId: PropTypes.string.isRequired,
    className: PropTypes.string,
    reveal: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    className: "",
    reveal: true,
    onSubmit: () => null,
  };

  constructor(props) {
    super(props);

    this.portalId = "7941245";
    this.state = {
      submitted: false,
    };
    this.containerRef = React.createRef(this.props.formId);
  }

  componentDidMount() {
    this.initJQuery();

    const { formId } = this.props;

    const script = document.getElementById("__hbspt_script__");

    if (formId) {
      window.hbspt
        ? this.createForm()
        : (script.onload = () => window.hbspt && this.createForm());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formId && prevProps.formId !== this.props.formId) {
      this.createForm();
    }
  }

  initJQuery = () => {
    // these are all methods required by HubSpot in order to useonFormSubmitted callback
    window.jQuery =
      window.jQuery ||
      (() => ({
        change: () => {},
        trigger: () => {},
      }));
  };

  onSubmit = () => {
    if (!this.state.submitted) {
      ga("send", {
        // hitType: "Event",
        eventCategory: "Form",
        eventAction: "Completion",
        eventLabel: "Contact",
        eventValue: 0,
      });
      this.props.onSubmit();
    }
    this.setState({ submitted: true });
    setTimeout(() => {
      this.createForm();
    }, 25000);
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 25000);
  };

  createForm = () => {
    const { formId } = this.props;
    const form = window.hbspt.forms.create({
      portalId: this.portalId,
      formId,
      onFormSubmitted: this.onSubmit,
    });

    form.onReady(() => {
      // when the form is loaded, remove any existing forms and append the new form
      const formParent = document.getElementById(
        `hsForm_${formId}`
      ).parentElement;
      const target = this.containerRef.current;

      formParent.remove();
      // const child = this.node.childNodes[0];

      // if (child) {
      //   this.node.removeChild(child);
      // }

      target.appendChild(formParent);
    });
  };

  render() {
    const { title, formId, className, reveal } = this.props;

    if (formId && this.portalId && window.hbspt) {
      if (reveal) {
        return (
          <div className={`hubspot_form ${className}`}>
            <Reveal>
              {title && <h2>{title}</h2>}
              <div className="hubspot_node" ref={this.containerRef} />
            </Reveal>
          </div>
        );
      } else {
        return (
          <div className={`hubspot_form ${className}`}>
            {title && <h2>{title}</h2>}
            <div className="hubspot_node" ref={this.containerRef} />
          </div>
        );
      }
    }

    return <div className="hubspot_no_form" />;
  }
}
