import styles from "./Header.scss";
import { _classes } from "@scripts/utilities/helpers";
import { useViewportContext } from "@scripts/providers";
import useOnScroll from "@scripts/hooks/useOnScroll";
import { useState } from "react";
import { Container, Text, Link, Modal } from "@scripts/widgets";
import MobileNav from "@scripts/partials/nav/MobileNav/MobileNav";
import DesktopNav from "@scripts/partials/nav/DesktopNav/DesktopNav";
import { useAppState } from "@scripts/providers";
import APP_CONFIG from "../../../config/appConfig";
import { HubspotContainer } from "@scripts/partials";
import Logo from "../../../../images/logo.jpg";

const cl = _classes(styles);

Header.propTypes = {};

export default function Header() {
  const { viewport } = useViewportContext();
  const [scrolled, setScrolled] = useState(false);

  const { childrenById, byTemplate, contactModalOpen, toggleContactModal } =
    useAppState();

  const footerPage = byTemplate("footer")[0];

  const items = childrenById(1).filter(
    (item) => item.showinnav && !item.template.match(/footer|specs/)
  );

  const headerHubspotFormId = byTemplate("home")[0].video_title;

  useOnScroll(() => {
    setScrolled(window.scrollY > 200);
  });

  const renderLogo = () => {
    return (
      <Link className={cl("logo")} path="/">
        <img src={Logo} />
      </Link>
    );
  };
  const getPreviousUrl = () => {
    const currentUrl = window.location.href;
    const previousUrlWithoutContact = currentUrl.replace(/\?contact=1$/, "");
    window.history.replaceState({}, "", previousUrlWithoutContact);
  };

  const renderNav = () => {
    const openContactModalFromQueryParams = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const contactQueryParam = urlSearchParams.get("contact");

      if (contactQueryParam === "1") {
        toggleContactModal(true);
      }
    };

    openContactModalFromQueryParams();

    if (viewport.is("tablet")) {
      return (
        <MobileNav
          items={items}
          scrolled={scrolled}
          toggleContactModal={() => toggleContactModal(!contactModalOpen)}
        >
          {renderLogo()}
        </MobileNav>
      );
    }

    return (
      <DesktopNav
        items={items}
        toggleContactModal={() => toggleContactModal(!contactModalOpen)}
      >
        {renderLogo()}
      </DesktopNav>
    );
  };
  return (
    <header className={cl(["root", scrolled && "scrolled"])}>
      <Container className={cl("container")}>{renderNav()}</Container>

      <Modal
        open={contactModalOpen}
        onClose={() => {
          toggleContactModal(false);
          getPreviousUrl();
        }}
        modalClassname={cl("modal")}
      >
        <div className={cl("contact")}>
          <div className={cl("left")}>
            <Text
              text={footerPage.h2}
              variant="h2"
              component="h3"
              className={cl(["text", "title"])}
            />
            <p
              className={cl("left-side-blurb")}
              dangerouslySetInnerHTML={{ __html: footerPage.blurb6 }}
            />
            <Link path={`tel:${footerPage.h3}`}>
              <Text
                component="p"
                text={footerPage.h3}
                variant="body1"
                className={cl(["text", "phone"])}
              />
            </Link>
            <Text
              component="p"
              text={"300 Kent Avenue"}
              variant="body1"
              className={cl(["text"])}
            />
            <Text
              component="p"
              text={"Brooklyn, NY 11249"}
              variant="body1"
              className={cl(["text"])}
            />
          </div>
          <div className={cl("form")}>
            <HubspotContainer
              formId={headerHubspotFormId && headerHubspotFormId}
              className={"hubspot-light"}
            />
            {/* <div className={cl("contact_info")}>
              <Link path={`tel:${footerPage.h3}`}>
                <Text
                  component="p"
                  text={footerPage.h3}
                  variant="body1"
                  className={cl(["text", "phone"])}
                />
              </Link>
              <Text
                component="p"
                text={"300 Kent Avenue"}
                variant="body1"
                className={cl(["text"])}
              />
              <Text
                component="p"
                text={"Brooklyn, NY 11249"}
                variant="body1"
                className={cl(["text"])}
              />
            </div> */}
          </div>
        </div>
      </Modal>
    </header>
  );
}
