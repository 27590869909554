import { _classes } from "@scripts/utilities/helpers";
import styles from "./HomeHero.scss";
import { Container, Text } from "@scripts/widgets";
import Carousel from "@scripts/widgets/Carousel";
import RoundButtons from "@scripts/partials/RoundButtons";
import { useViewportContext } from "@scripts/providers";
import VideoPlayer from "../../../widgets/VideoPlayer";

const cl = _classes(styles);

HomeHero.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  videoURL: PropTypes.string,
};

export default function HomeHero({
  images = [],
  title,
  subtitle,
  text,
  videoURL,
}) {
  const { viewport } = useViewportContext();

  return (
    <section className={cl(["root", videoURL ? "video" : ""])}>
      <div className={cl("carousel_outer")}>
        <div className={cl("carousel")}>
          {videoURL && (
            <VideoPlayer controls={false} autoplay loop src={videoURL} muted />
          )}
          {!videoURL && <Carousel slides={images} hasOverlay />}
        </div>
        <RoundButtons heroCta />
      </div>

      <Container>
        <div className={cl("inner")}>
          <div className={cl("intro")} id="intro">
            {viewport.is("mobile") && (
              <Text text={title} variant="h1" component="h1" />
            )}
            <Text
              text={subtitle}
              variant="h2"
              component="h2"
              font="light"
              className={cl("subtitle")}
            />
            <div
              className={cl("text")}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
