import styles from "./container.scss";
import { _classes } from "@scripts/utilities/helpers";
import useAppState from "@scripts/providers/AppProvider";

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  extraPadding: PropTypes.bool,
};

export default function Container({ children, className = "", extraPadding }) {
  const { accessible } = useAppState();
  const cl = _classes(styles);

  return (
    <div className={cl(["root", extraPadding ? "extra" : "", className])}>
      {children}
    </div>
  );
}
