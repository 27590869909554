import { useEffect } from "react";

export default function useOnEscape(callback, dep) {
  const listener = (event) => {
    if (event.key === "Escape") {
      callback();
    }
  };

  const removeListener = () =>
    window?.document?.removeEventListener("keyup", listener);

  const addListener = () =>
    window?.document?.addEventListener("keyup", listener);

  useEffect(() => {
    addListener();
    return removeListener;
  }, [dep]);

  return {};
}
