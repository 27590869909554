import { _classes } from "@scripts/utilities/helpers";
import styles from "./OfficesFloorPlans.scss";
import { Container, Text, Image, Link } from "@scripts/widgets";
import { directionArrow } from "@scripts/widgets/SVG";
import { useState } from "react";
import { ArrowCTA } from "@scripts/partials";
import { parse } from "@scripts/utilities/helpers";
import { useViewportContext } from "../../../providers";

const cl = _classes(styles);

OfficesFloorPlans.propTypes = {
  floorPlans: PropTypes.array,
  testFits: PropTypes.array,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default function OfficesFloorPlans({
  cta,
  floorPlans = [],
  testFits = [],
  className = "",
}) {
  const [selected, setSelected] = useState(10);

  const style1 = {
    fill: "none",
    stroke: "rgb(0, 0, 0)",
    strokeMiterlimit: "10",
    strokeWidth: "2px",
  };

  const { viewport } = useViewportContext();
  const isMobile = viewport.is("tablet");
  const ctaButton = cta.link !== "" && cta;
  const floorplansCTA = parse(floorPlans[selected]?.buttonlink1);

  const YELLOW = "#ffda00";

  const renderDownloadCTA = (link, text) => {
    return (
      <Link className={cl("cta")} path={link} target="_blank">
        <ArrowCTA direction="down" download />
        <Text
          text={text}
          component="h2"
          variant="h2"
          className={cl(["text"])}
          font="bold"
        />
      </Link>
    );
  };

  const renderBuildingGraphic = () => (
    <div className={cl("building_graphic")}>
      <svg
        viewBox="128.079 52.363 438.523 614.004"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="1/3-leased">
            <stop offset="33.33%" stop-color="#9a9a9a" />
            <stop offset="33.34%" stop-color="white" />
          </linearGradient>
        </defs>
        <g
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            id="Cellar"
            className={cl(["floor", "disabled"])}
            x="16.9"
            y="559.4"
            width="388.7"
            height="43.3"
            fill="#9a9a9a"
          />
          <text
            x="200"
            y="590"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            EQUIONOX FITNESS CLUB
          </text>
        </g>
        <g
          id="_x31_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            className={cl(["floor", "disabled"])}
            x="75.2"
            y="519.6"
            width="248.7"
            height="39.7"
            id="floor_1"
            fill="#9a9a9a"
          />
          <text
            x="200"
            y="545"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LEASED
          </text>
        </g>
        <g
          id="_x32_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            fill="#9a9a9a"
            className={cl(["floor", "disabled"])}
            x="75.2"
            y="484.5"
            width="248.7"
            height="35.1"
          />
          <text
            x="200"
            y="510"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LEASED
          </text>
        </g>
        <g
          id="_x33_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            fill="#9a9a9a"
            className={cl(["floor", "disabled"])}
            x="75.2"
            y="449.4"
            width="248.7"
            height="35.1"
          />
          <text
            x="200"
            y="474"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LEASED
          </text>
        </g>
        <g
          id="_x34_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            fill="#9a9a9a"
            className={cl(["floor", "disabled"])}
            x="75.2"
            y="414.3"
            width="248.7"
            height="35.1"
          />
          <text
            x="200"
            y="438"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LEASED
          </text>
        </g>
        <g
          id="_x35_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(3)}
            fill={selected == 3 ? YELLOW : "white"}
            className={cl(["floor", selected == 3 ? "selected" : ""])}
            x="75.2"
            y="385.3"
            width="248.7"
            height="29"
          />
        </g>
        <g
          id="_x36_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(4)}
            fill={selected == 4 ? YELLOW : "white"}
            className={cl(["floor", selected == 4 ? "selected" : ""])}
            x="75.2"
            y="356.4"
            width="248.7"
            height="29"
          />
          <text
            x="200"
            y="378"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            PRE-BUILT
          </text>
        </g>
        <g
          id="_x37_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(5)}
            fill={selected == 5 ? YELLOW : "url(#1/3-leased)"}
            className={cl(["floor", selected == 5 ? "selected" : ""])}
            x="75.2"
            y="328.4"
            width="248.7"
            height="29"
          />
          <text
            x="200"
            y="350"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LEASED
          </text>
        </g>
        <g
          id="_x38_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(6)}
            fill={selected == 6 ? YELLOW : "white"}
            className={cl(["floor", selected == 6 ? "selected" : ""])}
            x="75.2"
            y="300"
            width="248.7"
            height="29"
          />
        </g>
        <g
          id="_x39_F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(7)}
            fill={selected == 7 ? YELLOW : "url(#1/3-leased)"}
            className={cl(["floor", selected == 7 ? "selected" : ""])}
            x="75.2"
            y="271.5"
            width="248.7"
            height="29"
          />
          <text
            x="200"
            y="290"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LEASED
          </text>
        </g>
        <g
          id="_x31_0F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(8)}
            fill={selected == 8 ? YELLOW : "white"}
            className={cl(["floor", selected == 8 ? "selected" : ""])}
            x="75.2"
            y="243.5"
            width="248.7"
            height="29"
          />
        </g>
        <g
          id="_x31_1F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <rect
            onClick={() => setSelected(9)}
            fill={selected == 9 ? YELLOW : "white"}
            className={cl(["floor", selected == 9 ? "selected" : ""])}
            x="75.2"
            y="214.6"
            width="248.7"
            height="29"
          />
        </g>
        <g
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
          fill="#fff"
        >
          <path
            d="M77.8,185.7c-1.7,8.3-2.7,16.9-2.7,25.7v3.2h248.7v-3.2c0-8.8-0.9-17.4-2.7-25.7H77.8z"
            id="_x31_2F"
            onClick={() => setSelected(10)}
            fill={selected == 10 ? YELLOW : "white"}
            className={cl(["floor", selected == 10 ? "selected" : ""])}
          />
          <text
            x="200"
            y="208"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            PRE-BUILT
          </text>
        </g>
        <g
          id="_x31_4F"
          class="st0"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <path
            d="M77.6,186.7h243.7c-2.1-10.2-5.3-19.9-9.7-29H87.3C83,166.8,79.7,176.5,77.6,186.7z"
            onClick={() => setSelected(11)}
            fill={selected == 11 ? YELLOW : "white"}
            className={cl(["floor", selected == 11 ? "selected" : ""])}
          />
        </g>
        <g
          id="_x31_5F"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <path
            class="st2"
            d="M87.4,157.5h224.1c-19.5-40.5-60.4-68.8-108-70.3h-8.1C147.8,88.7,107,117,87.4,157.5z"
            fill="#9a9a9a"
            className={cl(["floor", "disabled"])}
          />
          <text
            x="200"
            y="125"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            PENTHOUSE
          </text>
          <text
            x="200"
            y="150"
            font-size="20"
            fill="black"
            text-anchor="middle"
          >
            LOUNGE
          </text>
        </g>
        <g
          id="Structure"
          transform="matrix(1.006866, 0, 0, 1, 133.875094, 58.26611)"
        >
          <g>
            <path
              class="st3"
              d="M199.5,87.1L199.5,87.1c-68.7,0-124.3,55.7-124.3,124.3v348h248.7v-348C323.8,142.8,268.2,87.1,199.5,87.1z"
              style={style1}
            />
            <rect
              fill={"white"}
              x="31.5"
              y="312.5"
              class="st4"
              width="16"
              height="246.9"
              style={style1}
            />
            <rect
              fill={"white"}
              x="348.4"
              y="180.6"
              class="st4"
              width="41.6"
              height="378.7"
              style={style1}
            />
            <polygon
              class="st4"
              points="390,180.6 348.4,180.6 357.1,1 381.3,1 &#9;&#9;"
              style={style1}
            />
            <rect
              fill={"white"}
              x="16.9"
              y="559.4"
              class="st3"
              width="388.7"
              height="43.3"
              style={style1}
            />
            <line
              class="st3"
              x1="0"
              y1="559.4"
              x2="425.4"
              y2="559.4"
              style={style1}
            />
            <line
              class="st3"
              x1="87.7"
              y1="157.7"
              x2="311.2"
              y2="157.7"
              style={style1}
            />
            <line
              class="st3"
              x1="78.4"
              y1="186.1"
              x2="320.6"
              y2="186.1"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="214.6"
              x2="323.8"
              y2="214.6"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="243.1"
              x2="323.8"
              y2="243.1"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="271.5"
              x2="323.8"
              y2="271.5"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="300"
              x2="323.8"
              y2="300"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="328.4"
              x2="323.8"
              y2="328.4"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="356.9"
              x2="323.8"
              y2="356.9"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="385.3"
              x2="323.8"
              y2="385.3"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="414.3"
              x2="323.8"
              y2="414.3"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="484.5"
              x2="323.8"
              y2="484.5"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="449.4"
              x2="323.8"
              y2="449.4"
              style={style1}
            />
            <line
              class="st3"
              x1="75.1"
              y1="519.6"
              x2="323.8"
              y2="519.6"
              style={style1}
            />
          </g>
        </g>
      </svg>
    </div>
  );

  return (
    <section className={cl(["root", className])}>
      <Container>
        <Text
          text={"Availability"}
          component="h2"
          variant="h1"
          className={cl(["text", "availability_title"])}
        />
        {/* {!isMobile && ( */}
        <Text
          text={`${floorPlans[selected]?.h1} - ${floorPlans[selected]?.h2}`}
          component="h3"
          variant="h2"
          className={cl(["text", "subtitle"])}
        />
        {/* )} */}

        <div>
          <div className={cl("inner")}>
            <div className={cl("image")}>
              <Image src={floorPlans[selected]?.image1} type="element" />
            </div>
            <div className={cl("graphic_wrapper")}>
              <div className={cl("graphic_controls")}>
                <button
                  className={cl("arrow_up")}
                  type="button"
                  onClick={() =>
                    setSelected(Math.min(selected + 1, floorPlans.length - 2))
                  }
                >
                  {directionArrow}
                </button>
                <Text
                  text={floorPlans[selected]?.h1}
                  component={"p"}
                  variant="body1"
                  className={cl("controls_text")}
                />
                <button
                  className={cl("arrow_down")}
                  type="button"
                  // onClick={() => setSelected(Math.max(selected - 1, 2))}
                  onClick={() => setSelected(Math.max(selected - 1, 3))}
                >
                  {directionArrow}
                </button>
              </div>
              {renderBuildingGraphic()}
            </div>
          </div>
        </div>

        <div className={cl("test_fits")}>
          <Text
            text={"Test Fits"}
            component="h3"
            variant="h2"
            className={cl(["text"])}
          />

          <ul className={cl("test_fits__list")}>
            {testFits.map((testFit) => (
              <li key={testFit.id} className={cl("test_fits__image")}>
                <Image src={testFit.image1} alt={"test fit"} type="element" />
              </li>
            ))}
          </ul>
          {ctaButton.link &&
            ctaButton.text &&
            renderDownloadCTA(ctaButton.link, ctaButton.text)}
        </div>
      </Container>
    </section>
  );
}
