import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/hamburger.scss";
const cl = _classes(styles);

Hamburger.propTypes = {
  toggle: PropTypes.func,
  open: PropTypes.bool,
};
export default function Hamburger({ toggle, open }) {
  return (
    <div className={`${cl("_")} ${open && cl("open")}`} onClick={toggle}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
