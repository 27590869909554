import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { Controller } from "react-hook-form/dist/index.ie11";
import Select from "react-select";
import { useFormContext } from "../FormProvider";
import { v4 as uuidv4 } from "uuid";
import { _classes } from "@scripts/utilities/helpers";
import styles from "./SelectInput.scss";
import { useState } from "react";

const cl = _classes(styles);

const UUID = uuidv4();

SelectInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  variant: PropTypes.string,
};

SelectInput.defaultProps = {
  className: "",
  backgroundColor: "",
};

export default function SelectInput({
  name,
  placeholder,
  label,
  options = [],
  rules,
  className,
  onSelect = () => null,
  variant = "",
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;
  const [focused, setFocus] = useState(false);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, value }) => {
        return (
          <div
            className={cl([
              "root",
              (focused || value) && "focus",
              variant,
              className,
            ])}
          >
            <Label value={label} htmlFor={id} variant={variant} />
            <div className={cl("control")}>
              <Select
                onFocus
                inputProps={{ id }}
                aria-labelledby="aria-label"
                name={name}
                label={label}
                placeholder={placeholder}
                value={options.find((rec) => rec.value === value)}
                onChange={(selected) => {
                  onChange(selected ? selected.value : null);
                  onSelect(selected ? selected.value : null);
                }}
                options={options}
                classNamePrefix={"select"}
                className={"select"}
                isSearchable={false}
              />
            </div>
            <ErrorMessage error={errors[name]} />
          </div>
        );
      }}
    />
  );
}
