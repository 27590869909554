import { Link, Text } from "@scripts/widgets";
import styles from "./CTA.scss";
import { _classes } from "@scripts/utilities/helpers";

const cl = _classes(styles);

CTA.propTypes = {
  variant: PropTypes.oneOf(["primary-dark", "primary-light", "submit"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fullWidth: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  link: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  external: PropTypes.bool,
};

export default function CTA({
  variant = "primary-light",
  size = "medium",
  text,
  onClick,
  className = "",
  fullWidth,
  link,
  id,
  type = "button",
  external,
}) {
  const classes = [
    "inner",
    styles[variant] || "",
    styles[size] || "",
    fullWidth ? "full_width" : "",
  ];

  const content = () => (
    <Text component={"span"} className={cl("text")} text={text} />
  );

  if (link) {
    return (
      <div className={cl(["root", className])} id={id}>
        <Link
          path={link}
          onClick={onClick}
          className={cl(classes)}
          external={external}
        >
          {content()}
        </Link>
      </div>
    );
  }

  return (
    <div className={cl(["root", className])} id={id}>
      <button id={id} type={type} onClick={onClick} className={cl(classes)}>
        {content()}
      </button>
    </div>
  );
}
