import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import { Header, Footer } from "./partials";
import APP_CONFIG from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect } from "react";
import useAppState from "./providers/AppProvider.js";
import { AnimatePresence, motion } from "./utilities/FramerMotion";
import zenscroll from "zenscroll";
import CookiePopup from "./partials/CookiePopup/CookiePopup.js";
import BannerForm from "./form/bannerForm/index.js";
App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

zenscroll.setup(500, 40);

export default function App({ location }) {
  const { byPath, accessible } = useAppState();

  // strip out all styles for accessibility mode
  useEffect(() => {
    const els = document.querySelectorAll(".accessible-styles");

    [
      ...document.querySelectorAll("link[rel='stylesheet']"),
      ...document.querySelectorAll("style"),
    ].forEach((sheet) => (sheet.disabled = accessible));

    setTimeout(() => {
      [
        ...document.querySelectorAll("link[rel='stylesheet']"),
        ...document.querySelectorAll("style:not(.accessible-styles"),
      ].forEach((sheet) => (sheet.disabled = accessible));
    }, 500);

    if (accessible) {
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style class="accessible-styles">
          body {
            font-family: "Arial" !important;
            max-width: 1400px;
            margin: 20px auto;
            padding: 20px;
          }

          nav ul, nav li {
            margin: 0px !important;;
          }

          nav span {
            font-size: 14px !important;;
          }
    
          img, svg{
            display: none !important;
          }

          * {
            cursor: auto !important;
            border-color: black !important;
          }

          *:not(a){ 
            color: black;
          }
        </style>`
      );
    } else {
      els.forEach((el) => document.head.removeChild(el));
    }
  }, [accessible, location.pathname]);

  useEffect(() => {
    pageChangeRoutine();
    setTimeout(hideLoader, APP_CONFIG.loadingDuration);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
  }, [location.pathname, location.searchj]);

  const hideLoader = () =>
    (document.querySelector(".site-loader").className +=
      " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    window.scrollTo(0, 0);
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }
  const isHomepage = location.pathname === "/" ? true : false;
  return (
    <div className={`app`}>
      <Header />
      <Routes />
      <CookiePopup />
      <AnimatePresence exitBeforeEnter>
        <motion.footer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
          layout
          key={location.key}
        >
          <Footer homepage={isHomepage} />
        </motion.footer>
      </AnimatePresence>
    </div>
  );
}
