import styles from "./wysiwyg.scss";
import { _classes } from "@scripts/utilities/helpers";

const cl = _classes(styles);

Wysiwyg.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
};

export default function Wysiwyg({ html = "", className = "" }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={cl(["root", className])}
    />
  );
}
