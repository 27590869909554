import { useEffect, useState } from "react";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import { AnimatePresence, motion } from "../utilities/FramerMotion";
import { buildNestioUrl } from "../utilities/helpers";
import viewsPaths from "../utilities/viewPaths";

Routes.propTypes = {};

export default function Routes() {
  const { content, location, nestio } = useAppState();

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const baseRoutes = createBaseRoutes();
    setRoutes(baseRoutes);
  }, []);

  const allnestio =
    nestio &&
    nestio.map((a) => a.items).reduce((sum, acc) => sum.concat(acc), []);

  const officeRoutes = () => {
    const Component = viewsPaths[`office`];

    return (
      allnestio &&
      allnestio.length > 0 &&
      allnestio?.map((page) => {
        const path = buildNestioUrl(page, "offices");

        return (
          <Route
            exact
            key={page.id}
            path={path}
            render={(props) => (
              <LazyBoundary fallback={<div />}>
                <ErrorBoundary>
                  <Component page={page} {...props} nestioData={allnestio} />
                </ErrorBoundary>
              </LazyBoundary>
            )}
          />
        );
      })
    );
  };

  const createBaseRoutes = () => {
    const donotroute = ["footer", "header"];
    const setPage = (page) => page;

    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const template = page.template.toLowerCase();
        const Component = viewsPaths[`${template}`] || viewsPaths["default"];
        const formattedPage = setPage(page);

        return (
          !donotroute.includes(page.template) && (
            <Route
              exact
              key={formattedPage.id}
              path={formattedPage.path}
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component page={formattedPage} {...props} />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          )
        );
      })
      .concat(officeRoutes())
      .concat(
        <Route
          path="/"
          key="redirectAllElse"
          render={() => <Redirect to="/" />}
        />
      );
  };

  return (
    <main>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
          className="page"
          key={location.key}
          layout
        >
          <Switch location={location}>{routes}</Switch>
        </motion.div>
      </AnimatePresence>
    </main>
  );
}
