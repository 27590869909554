import { _classes } from "@scripts/utilities/helpers";
import styles from "./PressList.scss";
import { Container, Text, Image, Link } from "@scripts/widgets";
import { ArrowCTA } from "@scripts/partials";
import dayjs from "dayjs";

const cl = _classes(styles);

PressList.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export default function PressList({ title, items = [] }) {
  console.log(items);
  return (
    <section className={cl("root")}>
      <Container className={cl("container")} extraPadding>
        <Text
          text={title}
          variant="h1"
          component="h1"
          className={cl(["text", "title"])}
        />

        <ul className={cl("list")}>
          {items.map((pressItem) => (
            <li className={cl("item")} key={pressItem.id}>
              <Link path={pressItem.h3} external>
                <div className={cl("item_image")}>
                  <Image
                    src={pressItem.image1}
                    alt={pressItem.image1_alt_text}
                  />
                  <ArrowCTA variant="press" className={cl("cta")} />
                </div>
                <Text
                  text={pressItem.h2}
                  className={cl(["text", "item_author"])}
                  variant="body1"
                  component="h3"
                />
                <Text
                  text={pressItem.h1}
                  className={cl(["text", "item_title"])}
                  variant="h2"
                  component="h2"
                />
                <Text
                  text={dayjs(pressItem.h4?.replace(/-/g, "/")).format(
                    "MMM DD, YYYY"
                  )}
                  className={cl(["text", "item_date"])}
                  variant="body2"
                  component="p"
                />
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
