import { Controller } from "react-hook-form/dist/index.ie11";
import { Label, ErrorMessage, useFormContext } from "@scripts/form";
import { v4 as uuidv4 } from "uuid";
import styles from "./CheckboxGroup.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";
import { checkmark } from "@scripts/widgets/SVG";
const cl = _classes(styles);

const UUID = uuidv4();

export default function CheckboxGroup({
  name,
  label,
  rules,
  className = "",
  onSelect,
  options = [],
  variant = "",
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[]}
      render={({ onChange, value = [] }) => (
        <div className={cl(["root", className, variant])}>
          <Label
            value={label}
            htmlFor={id}
            variant={variant}
            className={cl("label")}
          />
          <ul className={cl("list")}>
            {options.map((opt) => {
              const isSelected = value[opt.label];

              return (
                <li
                  className={cl(["item", isSelected && "selected"])}
                  key={opt.value}
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    let newValue = { ...value };
                    if (newValue[opt.label]) {
                      delete newValue[opt.label];
                    } else {
                      newValue[opt.label] = opt.value;
                    }
                    onChange(newValue);
                    onSelect(newValue);
                    if (isSelected) {
                      e?.currentTarget?.blur();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" ||
                      e.key === "NumpadEnter" ||
                      e.key == " "
                    ) {
                      e.preventDefault();
                      let newValue = { ...value };
                      if (newValue[opt.label]) {
                        delete newValue[opt.label];
                      } else {
                        newValue[opt.label] = opt.value;
                      }
                      onChange(newValue);
                      onSelect(newValue);
                    }
                  }}
                >
                  <span className={cl(["checkbox", isSelected && "selected"])}>
                    {checkmark}
                  </span>
                  <Text
                    text={opt.label}
                    className={cl("text")}
                    variant={"body3"}
                  />
                </li>
              );
            })}
          </ul>
          <ErrorMessage error={errors[name]} />
        </div>
      )}
    />
  );
}
