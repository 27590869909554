import { Modal as RRModal } from "react-responsive-modal";
import styles from "./modal.scss";
import { _classes } from "@scripts/utilities/helpers";
import "react-responsive-modal/styles.css";
import { useEffect } from "react";

const cl = _classes(styles);

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  className: PropTypes.string,
  overlayClassname: PropTypes.string,
  modalClassname: PropTypes.string,
  closeIcon: PropTypes.node,
};

export default function Modal({
  children,
  onClose,
  open = false,
  overlayClassname = "",
  modalClassname = "",
  closeIcon,
}) {
  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = open ? "hidden" : "auto";
    }, 0);
  }, [open]);
  return (
    <RRModal
      open={open}
      onClose={onClose}
      center
      closeIcon={closeIcon}
      classNames={{
        overlay: cl(["overlay", overlayClassname]),
        modal: cl(["modal", modalClassname]),
      }}
    >
      {children}
    </RRModal>
  );
}
