import styles from "./ErrorMessage.scss";
import { Text } from "@scripts/widgets";

ErrorMessage.propTypes = {
  error: PropTypes.object,
};

export default function ErrorMessage({ error }) {
  return error ? (
    <Text
      className={styles.root}
      text={error?.message || "Invalid"}
      component="span"
      font="bold"
    />
  ) : null;
}
