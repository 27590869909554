import { _classes } from "@scripts/utilities/helpers";
import styles from "./IncentivesBlock.scss";
import { Container, Text } from "@scripts/widgets";

const cl = _classes(styles);

IncentivesBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  boxDescription: PropTypes.string,
  className: PropTypes.string,
};

export default function IncentivesBlock({
  title,
  subtitle,
  description,
  boxDescription,
  className = "",
}) {
  return (
    <div className={cl(["root", className])}>
      <div className={cl("box")}>
        <Text text={subtitle} variant="h2" component="h3" />
        <Text text={title} variant="body1" component="p" />
        <div
          dangerouslySetInnerHTML={{ __html: boxDescription }}
          className={cl("box_description")}
        />
      </div>
      <div className={cl("info")}>
        <Text
          text={title}
          variant="h2"
          component="h2"
          className={cl("title")}
        />
        <Text text={description} variant="body1" component="p" />
      </div>
    </div>
  );
}
