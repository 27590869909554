import styles from "./WilliamsburgTransportation.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Container, Text, Wysiwyg } from "@scripts/widgets";
import {
  subway,
  bus,
  ferry,
  bike,
  foot,
  jLine,
  zLine,
  mLine,
  lLine,
  gLine,
} from "../../../widgets/SVG";

const cl = _classes(styles);

WilliamsburgTransportation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.array,
};

export default function WilliamsburgTransportation({
  title,
  subtitle,
  content,
}) {
  const renderSubwaySVGs = (index) => {
    return (
      <div className={cl("content")}>
        <div className={cl("subway_title")}>
          <Wysiwyg html={index} />
        </div>
        <div className={cl("train_lines")}>
          <div className={cl("train_svg")}>{jLine}</div>
          <div className={cl("train_svg")}>{zLine}</div>
          <div className={cl("train_svg")}>{mLine}</div>
          <div className={cl("train_svg")}>{lLine}</div>
          <div className={cl("train_svg")}>{gLine}</div>
        </div>
      </div>
    );
  };

  const returnTransportationSVGs = (category) => {
    switch (category) {
      case "subway":
        return subway;
        break;
      case "bus":
        return bus;
        break;
      case "bike":
        return bike;
        break;
      case "ferry":
        return ferry;
        break;
      case "foot":
        return foot;
        break;
    }
  };

  const renderContentBlock = (arrayOfObjects) => {
    return (
      <>
        {arrayOfObjects.map((obj, i) => {
          return (
            <div className={cl(["container", `${obj.id}`])}>
              <div className={cl(["icon", obj.buttonswitch1 && "no_show"])}>
                {returnTransportationSVGs(obj.h2)}
              </div>
              {obj.h2 === "subway" ? (
                renderSubwaySVGs(obj.blurb1)
              ) : (
                <div className={cl("content")}>
                  <Wysiwyg html={obj.blurb1} />
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <section className={cl("root")}>
      <Container extraPadding>
        <Text
          text={title}
          variant="h1"
          component="h2"
          className={cl("title")}
        />
        <Text
          text={subtitle}
          variant="h2"
          component="h3"
          className={cl("subtitle")}
        />

        <div className={cl("grid_content")}>{renderContentBlock(content)}</div>
      </Container>
    </section>
  );
}
