import { debounce } from "@scripts/utilities/helpers";
import { useEffect } from "react";

export default function useOnScroll(callback, delay = 50) {
  const cb = debounce(delay, callback);

  const removeListener = () =>
    window?.document?.removeEventListener("scroll", cb);

  const addListener = () => window?.document?.addEventListener("scroll", cb);

  useEffect(() => {
    addListener();
    return removeListener;
  }, []);

  return { callback, delay };
}
