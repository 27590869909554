import { _classes } from "@scripts/utilities/helpers";
import styles from "./TextBlock2.scss";
import { Container, Text } from "@scripts/widgets";

const cl = _classes(styles);

TextBlock2.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  subtitle2: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.oneOf([
    "white",
    "darkGrey",
    "darkerGrey",
    "offWhite",
  ]),
  textColor: PropTypes.oneOf(["light", "dark"]),
  isPageTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default function TextBlock2({
  subtitle,
  title,
  subtitle2,
  text,
  backgroundColor = "white",
  textColor = "dark",
  isPageTitle,
  className = "",
}) {
  return (
    <section className={cl(["root", textColor, backgroundColor, className])}>
      <Container extraPadding>
        <div className={cl("inner")}>
          {(title || subtitle) && (
            <Text
              component={isPageTitle ? "h1" : "h2"}
              className={cl("title_wrap")}
            >
              {subtitle && (
                <Text
                  text={subtitle}
                  variant="h2"
                  className={cl(["text", "subtitle"])}
                />
              )}
              <br />
              <Text
                text={title}
                variant="h1"
                className={cl(["text", "title"])}
              />
            </Text>
          )}
          {subtitle2 && (
            <Text
              text={subtitle2}
              variant="h2"
              component={"h2"}
              className={cl(["text", "subtitle"])}
            />
          )}
          {text && (
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className={cl(["text", "content"])}
            />
          )}
        </div>
      </Container>
    </section>
  );
}
