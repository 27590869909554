import { Controller } from "react-hook-form/dist/index.ie11";
import { Label, ErrorMessage, useFormContext } from "@scripts/form";
import { v4 as uuidv4 } from "uuid";
import styles from "./StandardInput.scss";
import { _classes } from "@scripts/utilities/helpers";
import { useState } from "react";

const cl = _classes(styles);

const UUID = uuidv4();

StandardInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  middleware: PropTypes.func,
  variant: PropTypes.string,
};

StandardInput.defaultProps = {
  name: "input",
  label: "",
  placeholder: "",
  type: "text",
  defaultValue: "",
  className: "",
  middleware: (value) => value,
  variant: "dark",
};

export default function StandardInput({
  name,
  placeholder,
  type,
  label,
  rules,
  className = "",
  defaultValue,
  disabled,
  middleware,
  variant,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  const [focused, setFocus] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value = "" }) => (
        <div
          className={cl([
            "root",
            (focused || value) && "focus",
            variant,
            className,
          ])}
          style={{ display: type === "hidden" ? "none" : "block" }}
        >
          <Label value={label} htmlFor={id} variant={variant} />
          <div className={cl("control")}>
            <input
              id={id}
              disabled={disabled}
              onChange={(e) => onChange(middleware(e.target.value))}
              type={type}
              name={name}
              value={value}
              placeholder={placeholder}
              className={cl(["input", variant])}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </div>
          <ErrorMessage error={errors[name]} />
        </div>
      )}
    />
  );
}
