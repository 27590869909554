import styles from "./HomeSpecs.scss";
import { _classes, parse } from "@scripts/utilities/helpers";
import { useState, useEffect } from "react";
import { Container, CTA, Text } from "@scripts/widgets";
import { useAppState, useViewportContext } from "@scripts/providers";

const cl = _classes(styles);

export default function HomeSpecs() {
  const { byTemplate } = useAppState();
  const { viewport } = useViewportContext();
  const specs = byTemplate("specs")[0];
  const nodes = specs.fieldgroup1;
  const cta = parse(specs.buttonlink1, {});
  const viewBox = viewport.is("mobile")
    ? "-400 -400 800 800"
    : "-700 -400 1400 800";

  // FUN MATH THINGS INCOMING
  const CIRCLE_RADIUS = 330; // don't change or bad things will happen

  function degreesToRadians(deg) {
    return (deg * Math.PI) / 180;
  }
  function getCircleX(deg, radius) {
    return Math.round(Math.cos(degreesToRadians(deg)) * radius);
  }

  function getCircleY(deg, radius) {
    return Math.round(Math.sin(degreesToRadians(deg)) * radius);
  }

  function getCoordinatesFromDeg(deg) {
    return {
      x: getCircleX(deg, CIRCLE_RADIUS),
      y: getCircleY(deg, CIRCLE_RADIUS),
    };
  }

  const [active, setActive] = useState(nodes[0]);

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const updateActiveNode = () => {
      const currentIndex = nodes.findIndex((node) => node.h1 === active.h1);
      const nextIndex = (currentIndex + 1) % nodes.length;
      setActive(nodes[nextIndex]);
    };

    const id = setInterval(updateActiveNode, 3000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [active, nodes]);

  const handleMouseEnter = () => {
    clearInterval(intervalId);
  };

  const renderCircles = () => {
    return nodes.map((node, index) => {
      const circle = getCoordinatesFromDeg(Number(node.h2)) || 0;
      return (
        <g
          key={index}
          onMouseEnter={handleMouseEnter}
          onClick={() => setActive(node)}
          className={cl("node_group")}
        >
          <circle
            id={`node_circle_${index}`}
            cx={circle.x}
            cy={circle.y}
            r="22"
            fill="red"
            className={cl([
              "node_circle",
              active.h1 == node.h1 ? "active" : "",
            ])}
          />
          <text
            id={`node_text_${index}`}
            className={cl([
              "node_text",
              active.h1 == node.h1 ? "active" : "",
              circle.x < 0 ? "anchor_end" : "",
            ])}
            x={circle.x < 0 ? circle.x - 40 : circle.x + 40}
            y={circle.y + 10}
          >
            {node.h1}
          </text>
        </g>
      );
    });
  };

  // transforms and absolute positioning don't work in Safari
  // so we set the y coordinate manually
  function getTextPosition(arr, index) {
    const start = -(arr.length - 1) * 5;
    const offset = index * 10;
    const percentage = start + offset;
    return `${percentage}%`;
  }

  return (
    <section className={cl("root")}>
      <Container>
        <div className={cl("svg_container")}>
          <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
            <circle
              className="cls-1"
              cx="0"
              cy="0"
              r="330"
              fill="none"
              strokeWidth={"1px"}
              className={cl("ring")}
            />

            <path
              d={`M 0 0 L 330 0`}
              className={cl("line")}
              style={{
                transform: `rotate(${active.h2}deg)`,
              }}
            />
            {renderCircles()}
            <g id="active_text_group">
              {active.h3?.split(/\s/).map((text, index, arr) => (
                <text
                  key={index}
                  x="0%"
                  y={getTextPosition(arr, index)}
                  className={cl("detail_text")}
                >
                  {text}
                </text>
              ))}
            </g>
          </svg>
        </div>
        <Text
          text={active.h1}
          variant="h2"
          component="h3"
          className={cl("mobile_text")}
        />
        <div className={cl("cta_wrapper")}>
          <CTA text={cta.text} link={cta.link} variant="primary-dark" />
        </div>
      </Container>
    </section>
  );
}
